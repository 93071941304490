jQuery(function ($) {

    let libraryForm = $("#library_add_post"),
        lngthSlider = [],
        cloneId = 2,
        slider_counter = 1,
        checkerSelect = false,
        checkerArea = false,
        checkerTitle=false,
        selectBox = $('.form-item-box__select'),
        innerSelectBox = $('.form-item-box__select .mb-3');

    /*
   ===================================================================================================================
   //SELECT2 CATEGORY INIT and EVENTS on change
   ===================================================================================================================
   */

    $('.select-category').select2({dir: "rtl", minimumResultsForSearch: -1, width: '100%'}).on('change', function () {

        // let data = $(".select-category option:selected").text();
        let data = $(".select-category option:selected").val();

        if (data != 'default' && selectBox.hasClass('field-error') && innerSelectBox.hasClass('field-error')) {

            selectBox.removeClass('field-error');
            innerSelectBox.removeClass('field-error');
            $('.form-item-box__select-error').hide();
            checkerSelect = false;

        } else if (data == 'default') {

            checkerSelect = true;
            if (!selectBox.hasClass('field-error')) selectBox.addClass('field-error');
            if (!innerSelectBox.hasClass('field-error')) innerSelectBox.addClass('field-error');
            $('.form-item-box__select-error').show();

        }

    });

    /*
    ===================================================================================================================
    //tinymce INIT and EVENTS on change
    ===================================================================================================================
    */

    tinymce.init({
        selector: 'textarea#article_full',
        directionality: "rtl",
        plugins: 'lists',
        toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | numlist | bullist',
        menubar: false,

        init_instance_callback: function (editor) {
            editor.on('KeyDown', function (e) {
                console.log('KEY DOWN');

                if ($('.text_area').hasClass('field-error')) {
                    $('.text_area').removeClass('field-error');
                }

                $('#article_full-error').hide();
                checkerArea = false;

            });
        }
    });

    /*
   ===================================================================================================================
   //check YOUTUBE VIDEO
   ===================================================================================================================
   */

    function ytVidId(url) {
        var p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        return (url.match(p)) ? RegExp.$1 : false;
    }

    /*
    ===================================================================================================================
    //check YOUTUBE LIST
    ===================================================================================================================
    */

    function checkListId(url) {
        let video_id = url.split('list=')[1];
        let ampersandPosition = video_id.indexOf('&');
        if (ampersandPosition != -1) {
            video_id = video_id.substring(0, ampersandPosition);
        }
        return video_id;
        // console.log(video_id);
    }

    /*
    ===================================================================================================================
    //check VIMEO LIST
    ===================================================================================================================
    */

    function GetVimeoIDbyUrl(url) {
        var id = false;
        $.ajax({
            url: 'https://vimeo.com/api/oembed.json?url=' + url,
            async: false,
            success: function (response) {
                if (response.video_id) {
                    id = response.video_id;
                }
            }
        });
        return id;
    }

    /*
     ===================================================================================================================
     //LIBRARY FORM SLIDER REMOVE
     ===================================================================================================================
     */

    $(document).on("click", '.remove_slide', function () {

        event.preventDefault();
        $(this).closest(".single_slide_inner").remove();

        if (slider_counter > 0) {
            slider_counter--;
        }

    });

    /*
    ===================================================================================================================
    //LIBRARY FORM INSERT AND CHECKING VIDEOS
    ===================================================================================================================
    */

    $(document).on("click", '.insert_vid_btn', function (event) {
        event.preventDefault();

        let thiselem = $(this);

        let currentSlide = thiselem.parents().eq(3);
        console.log(currentSlide);

        if ($(this).hasClass('insert_you_btn')) {

            console.log('YOU')
            currentSlide.find('.you_iframe').show();
            var textareaValue = ytVidId(currentSlide.find(".youtube_code_video").val());
            var link = 'https://www.youtube.com/embed/' + textareaValue;
            console.log(link);
            currentSlide.find('.you_iframe').attr('src', link);

        } else if ($(this).hasClass('insert_vim_btn')) {

            console.log('VIM')
            currentSlide.find('.vim_iframe').show();
            var textareaValue = GetVimeoIDbyUrl(currentSlide.find(".vim_code_video").val());
            console.log('textareaValue-  ', currentSlide.find(".vim_code_video").val());
            var link = 'https://player.vimeo.com/video/' + textareaValue;
            console.log(link);
            currentSlide.find('.vim_iframe').attr('src', link);

        } else if ($(this).hasClass('insert_you_list_btn')) {

            console.log('LIST YOU')

            let textarea = checkListId(currentSlide.find(".youtube_list_code_video").val());

            let link = 'https://www.youtube.com/embed/videoseries?list=' + textarea;

            console.log('link-', link);

            currentSlide.find('.you_list_iframe').attr('src', link);

            currentSlide.find('.you_list_iframe').show();


        }

        // console.log(currentSlide);
    });

    // $(".yt-url").on("click", function (e) {
    //     var ytId = ytVidId(e.target.value);
    //     alert(e.target.value + "\r\nResult: " + (!ytId ? 'false' : ytId));
    // });

    /*
   ===================================================================================================================
   //LIBRARY FORM ADD SLIDE
   ===================================================================================================================
   */

    $(document).on("click", '.add_slide', function (event) {
        event.preventDefault();
        var countB = $('#all_slides_library').children().length,
            wrap = $(this).parents('.form').find('.all_slides');
        // console.log(cloneId);
        console.log(countB);
        radio_type = $(wrap).children('.single_slide_inner').first().find('.radio-btn-group.image_video'),
            clone = $('#all_slides_library').children('.single_slide_inner').first().clone();

        clone.find(".radio-btn-group.image_video input").prop("name", "video_for_slide_" + cloneId);
        clone.find(".type-video input").prop("name", "radio-button_vim_you_" + cloneId);
        clone.find('input[type="radio"]').prop("checked", false);
        clone.find('.video-select').removeClass('active');
        clone.find('.type-choice').removeClass('active');
        clone.find('input[type="text"]').val('');
        clone.find('.draghere').removeClass('active').css('background-image', '');

        $('#all_slides_library').append(clone);
        // $(wrap).children('.single_slide_inner').last().find('.radio-btn-group').children('.lbl_rdbtn').first().find('input').prop("checked", true);
        cloneId++;
        // countB++;
        console.log("counbt" + countB);
    });

    /*
     ===================================================================================================================
     //LIBRARY FORM  CHOOSE RADIO BTN
     ===================================================================================================================
    */

    $(document).on("click", '.radio-btn-group input[type="radio"]', function () {
        let thisSlide = $(this).parents().eq(2),
            type = $(this).val(),
            element = $(this).parents('.single_slide_inner').find("[data-choice='" + type + "']"),
            element_video = $(this).parents('.video-chooise').find("[data-choice='" + type + "']"),
            wrap = $(this).parents('.single_slide_inner'),
            wrap_video = $(this).parents('.video-chooise');
        // thisSlide.find('.radio_video_next.radio-btn-group').removeClass("radio_vr");

        if ($(this).parents('.type-choice').length == 0) {
            $(wrap).find('.type-choice').removeClass('active');
            $(element).addClass('active');
        } else {
            $(wrap_video).find('.video-select').removeClass('active');
            $(element_video).addClass('active');
        }
    });

    /*
     ===================================================================================================================
     //LIBRARY FORM  CLICK CHECKBOX
     ===================================================================================================================
    */

    $(document).on("click", '.terms_s', function () {
        if ($(this).find('#terms_n_con').is(':checked')) {

            $(".btn_submit").removeAttr("disabled");

        } else {

            $(".btn_submit").attr("disabled", "disabled");

        }
    });

    /*
     ===================================================================================================================
     //LIBRARY FORM  SUBMIT
     ===================================================================================================================
    */

    libraryForm.on("submit", function (event) {

        event.preventDefault();

        let allSlideIndex = 1,
            form = $(this),
            fd = new FormData(),
            featured_image = $(this).find('input[name="featured_image"]'),
            featured__file = featured_image[0].files[0],
            post_cont = tinymce.get("article_full").getContent(),
            all_slide_info = {},
            post_title = form.find("#post_title").val(),
            publication_date = form.find("#publicDate").val(),
            select_category = form.find(".select-category.category option:selected").val(),
            select_type = form.find(".select-category.type option:selected").val(),
            // post_thumb = featured__file,
            source_name = form.find("#source_name").val(),
            source_link = form.find("#source_link").val(),
            authors_name = form.find("#authors_name").val(),
            translator = form.find("#translator").val(),
            language = form.find("#language").val(),
            library_link = form.find("#link").val(),
            library_link_title = form.find("#link_title").val(),
            source_link_name = form.find("#source_link_name").val(),
            some_name = form.find("#some_name").val();
        // short_descr = form.find("#article_short").val();

        let test_array = [],
            countR = 1;

        if ($(".select-category").val() == 'default') {
            $('.form-item-box__select-error').show();
            // selectBox.append("  <div  class='error form-item-box__select-error' >שדה זה הוא שדה חובה</div>");
            innerSelectBox.addClass('field-error');
            selectBox.addClass('field-error');
            checkerSelect = true;

        } else {

            checkerSelect = false;

        }

        if (tinyMCE.get('article_full').getContent() == '') {

            $('#article_full-error').show();
            $('.text_area').addClass('field-error');
            checkerArea = true;

        } else {

            if ($('.text_area').hasClass('field-error')) {
                $('.text_area').removeClass('field-error');
            }

            $('#article_full-error').hide();
            checkerArea = false;

        }

        $('#library_add_post').validate({
                highlight: function (element) {
                    $(element).parent().addClass("field-error");
                },
                unhighlight: function (element) {
                    $(element).parent().removeClass("field-error");
                },
                rules: {
                    terms_n_con: {
                        required: true,
                    },
                    post_title: {
                        required: true,
                    },
                    language: {
                        required: true,
                    }
                },
            }
        );

        /*
        ===================================================================================================================
        //ADDITIONAL VALIDATION FOR FORM
        ===================================================================================================================
       */

        if (libraryForm.valid() && !checkerSelect && !checkerArea &&!checkerTitle) {

            $(".single_slide_inner").each(function () {

                let arraySlideinfo = [],
                    arraySLideImages = [],
                    radioVideoImage = $(this).find('input[name="video_for_slide_' + allSlideIndex + '" ]:checked').val(),
                    videoVimYouCode = $(this).find('input[name="radio-button_vim_you_' + allSlideIndex + '" ]:checked').val(),
                    videoYouCode = $(this).find('.youtube_video input[name="youtube_code_video"]').val(),
                    videoListYouCode = $(this).find('.youtube_list_video input[name="youtube_list_code_video"]').val(),
                    videoVimeoCode = $(this).find('.vimeo_video input[name="vimeo_code_video"]').val(),
                    slider_image = $(this).find('input[name="hero_banner"]'),
                    slider__file = slider_image[0].files[0];

                if (radioVideoImage == "radio_video_next") {

                    arraySlideinfo.push('Video');
                    test_array.push(slider__file);
                    countR++;

                    if (videoVimYouCode.length != 0) {
                        if (videoVimYouCode == 'vimeo_video') {
                            arraySlideinfo.push('Vimeo');
                            if (videoVimeoCode.length != 0) {
                                arraySlideinfo.push(GetVimeoIDbyUrl(videoVimeoCode));
                            }
                        } else if (videoVimYouCode == 'youtube_video') {
                            arraySlideinfo.push('Youtube');
                            if (videoYouCode.length != 0) {
                                arraySlideinfo.push(ytVidId(videoYouCode));
                            }
                        } else if (videoVimYouCode == 'youtube_list_video') {
                            arraySlideinfo.push('Youtube list');
                            if (videoListYouCode.length != 0) {
                                arraySlideinfo.push(checkListId(videoListYouCode));
                            }
                        }
                    }

                } else if (radioVideoImage == "image_next") {

                    arraySlideinfo.push('Image');
                    fd.append('image_' + countR, slider__file);
                    countR++;

                }

                all_slide_info[allSlideIndex] = arraySlideinfo;
                allSlideIndex++;
            });
            fd.append('some_name', some_name);
            fd.append('source_link', source_link);
            // fd.append('short_descr', short_descr);
            fd.append('source_link_name', source_link_name);
            fd.append('source_name', source_name);
            fd.append('select_type', select_type);
            fd.append('authors_name', authors_name);
            fd.append('translator', translator);
            fd.append('library_link', library_link);
            fd.append('library_link_title', library_link_title);
            fd.append('language', language);
            fd.append('all_slide_info', JSON.stringify(all_slide_info));
            fd.append('select_category', select_category);
            fd.append('publication_date', publication_date);
            fd.append('post_title', post_title);
            fd.append('post_thumb', featured__file);
            fd.append('post_cont', post_cont);
            fd.append('action', 'save_post_details_form');

            jQuery.ajax({
                url: ajax_url,
                type: 'POST',
                data: fd,
                contentType: false,
                processData: false,

                error: function (error) {
                    alert("Insert Failed" + error);
                },

                beforeSend: function () {
                    $('.modal-library').show();
                },

                success: function (response) {
	                const popupMessageAccess = document.querySelector('.popup_message_access');
                    $('.modal-library').hide();
                    $('.overlay_message_popup').fadeIn();
                    $('.overlay-popup').fadeIn();
	                popupMessageAccess && popupMessageAccess.classList.remove('disable');
                    $('input[type="text"]').val('');
                    let parent = $('form').find('.draghere');
                    $(parent).removeClass('active').css('background-image', '');
                    $('form').find('input[type=file]').val('');

                    $(".select-category").val("default");
                    $(".select-category").trigger('change');

                    // $('.form-item-box__select-error').hide();
                    // innerSelectBox.removeClass('field-error');
                    // selectBox.removeClass('field-error');

                    // $(".select-category").select2();
                    $('#terms_n_con').prop('checked', false);
                    $('.single_slide_inner').not(':first').remove();

                    tinyMCE.activeEditor.setContent("");

                    $(".btn_submit").attr("disabled", "disabled");
                    //
                    $('.popup-inner.popup_message_access ').removeClass('disable');


                }
            });
        }
    });

    /*
     ===================================================================================================================
         //LIBRARY FORM AJAX CHECK POST TITLE
     ===================================================================================================================
    */

    $('#library_add_post input[name="post_title"]').on('focusout', function () {

        console.log('FOCUS OUT');

        let title = $(this).val(),
            submit = $('#library_add_post input[type="submit"]'),
            parent = $(this).parent('div'),
            data = {
                action: 'check_title',
                title: title
            };
        // if ($('#library_add_post  .message').length > 0) {
        //
        //     parent.after('<p class="message" style="color: #8c0b16; font-weight: bold;"></p>')
        // }
        if (title.length != 0) {
            jQuery.ajax({


                url: ajax_url,
                type: 'POST',
                data: data,

                error: function (error) {
                    alert("Insert Failed" + error);
                },

                beforeSend: function () {
                    $('.modal-post__title').show();
                },

                success: function (response) {

                    $('.modal-post__title').hide();

                    if (response == 0) {
                        console.log('response == 0 ')
                        submit.removeAttr('disabled')
                        $('#post_title-error').hide();
                        parent.removeClass('unvalid')
                        $('.form-item__post-title .message').remove();
                        checkerTitle = false;

                    } else {

                        console.log('response = ', response)
                        // submit.attr('disabled', 'disabled')
                        if(!parent.hasClass('unvalid')){
                            parent.after('<p class="message" style="color: #8c0b16; font-weight: bold;margin-bottom: 0;"></p>')
                            $('.message').text(response)
                            parent.addClass('unvalid');
                        }


                        // $('#post_title-error').text(response);
                        // $('#post_title-error').show();

                        checkerTitle = true;
                    }
                }
            });
        }else{
            $('.message').remove();
            parent.removeClass('unvalid')
            // submit.removeAttr('disabled')
        }

        // $.post(ajax_url, data, function (responce) {
        //
        //     if (responce == 0) {
        //         console.log('responce == 0 ')
        //         submit.removeAttr('disabled')
        //         $('.message').text('')
        //         parent.removeClass('unvalid')
        //     } else {
        //
        //         console.log('responce = ',responce)
        //         submit.attr('disabled', 'disabled')
        //         $('.message').text(responce)
        //         parent.addClass('unvalid')
        //
        //     }
        //
        // })

    })

});
